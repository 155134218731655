import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import './Threat.css';


const CveListComponent = ({ glossaryData }) => {
  const [cveDetails, setCveDetails] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [lockedList, setLockedList] = useState([]);
  const [selectedSeverities, setSelectedSeverities] = useState([]);
  const [lockedSeverity, setlockedSeverity] = useState([]);
  const [lockedImpact, setlockedImpact] =useState([]);
  const [selectedImpactScoreRanges, setSelectedImpactScoreRanges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  
  const keywords = [ 
    'Microsoft',
    'Adobe',
    'Google',
    'Apple',
    'IBM',
    'Oracle',
    'AWS',
    'Meta',
    'SalesForce',
    'SAP',
  ];

  const severities = [
    'HIGH', 
    'MEDIUM',
    'LOW'
  ];

  const [impactScoreRanges, setImpactScoreRanges] = useState([
    { label: '10-8', range: { min: 8, max: 10 } },
    { label: '8-6', range: { min: 6, max: 8 } },
    { label: '6-4', range: { min: 4, max: 6 } },
    { label: '4-0', range: { min: 0, max: 4 } },
  ]);


  useEffect(() => {
    console.log("Selected Values:", selectedValues);
    console.log("Severity Value:", selectedSeverities);
    console.log("Impact Value:", selectedImpactScoreRanges)
    console.log("Locked List:", lockedList);
    console.log("Locked Severity:", lockedSeverity)
    console.log("Locked Impact:", lockedImpact)
    if (lockedList.length > 0 && lockedSeverity.length > 0  && lockedImpact.length > 0) {
      fetchCVEData();
    }
  }, [selectedValues, lockedList, selectedSeverities, lockedSeverity, selectedImpactScoreRanges, lockedImpact]);

  const proxyUrl = 'https://cyberrisk.api.assentian.com/'; // URL of the proxy server

  const fetchCVEData = async () => {
    setLoading(true);
    let i = 0;
    const requests = lockedList.map(value =>
      fetch(`${proxyUrl}cves?resultsPerPage=250&startIndex=0&keywordSearch=${value}`)
        .then(response => response.json())
    );

    Promise.all(requests)
      .then(responses => {
        let newCveDetails = [];

        responses.forEach(data => {
          const cveItems = data.vulnerabilities;

          cveItems.forEach(cveItem => {
            const cveIDs = cveItem.cve.id;
            const description = cveItem.cve.descriptions[0].value;

            let exploitScore;
            if (cveItem.cve.metrics.cvssMetricV2) {
              exploitScore = cveItem.cve.metrics.cvssMetricV2[0].exploitabilityScore;
            } else if (cveItem.cve.metrics.cvssMetricV3) {
              exploitScore = cveItem.cve.metrics.cvssMetricV3[0].exploitabilityScore;
            }

            let impScore;
            if (cveItem.cve.metrics.cvssMetricV2) {
              impScore = cveItem.cve.metrics.cvssMetricV2[0].impactScore;
            } else if (cveItem.cve.metrics.cvssMetricV3) {
              impScore = cveItem.cve.metrics.cvssMetricV3[0].impactScore;
            }

            let severity;
            if (cveItem.cve.metrics.cvssMetricV2) {
              severity = cveItem.cve.metrics.cvssMetricV2[0].baseSeverity;
            } else if (cveItem.cve.metrics.cvssMetricV3) {
              severity = cveItem.cve.metrics.cvssMetricV3[0].baseSeverity;
            }

            let productType = lockedList[i];

            newCveDetails.push({
              CveID: cveIDs,
              Description: description,
              Severity: severity,
              Exploitability_Score: exploitScore,
              Impact_Score: impScore,
              Product_Type: productType,
            });
          });

          newCveDetails.sort((a, b) => {
            const order = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];
            return order.indexOf(b.Severity) - order.indexOf(a.Severity);
          });

          i++;
        });

        setCveDetails(newCveDetails);
        console.log(newCveDetails)
        setLoading(false);
      });
  };

  const toggleKeywords = (value) => {
    setSelectedValues((prevSelectedValues) => {
      if (prevSelectedValues.includes(value)) {
        return prevSelectedValues.filter(item => item !== value);
      } else {
        return [...prevSelectedValues, value];
      }
    });
  };

  const isSelected = (value) => {
    return selectedValues.includes(value);
  };
  const toggleImpactScoreRange = (range) => {
    setSelectedImpactScoreRanges((prevSelectedRanges) => {
      if (prevSelectedRanges.includes(range)) {
        return prevSelectedRanges.filter((item) => item !== range);
      } else {
        return [...prevSelectedRanges, range];
      }
    });
  };
  const isImpactScoreRangeSelected = (range) => {
    return selectedImpactScoreRanges.includes(range);
  };


  const toggleSeverity = (severity) => {
    setSelectedSeverities((prevSelectedSeverities) => {
      if (prevSelectedSeverities.includes(severity)) {
        return prevSelectedSeverities.filter(item => item !== severity);
      } else {
        return [...prevSelectedSeverities, severity];
      }
    });
  };

  const isSeveritySelected = (severity) => {
    return selectedSeverities.includes(severity);
  };

  const filteredCveDetails = cveDetails.filter((cveDetail) => {
    return (
      (lockedImpact.length === 0 ||
        lockedImpact.some(
          (range) =>
            cveDetail.Impact_Score >= range.range.min && cveDetail.Impact_Score <= range.range.max
        )) &&
      (lockedSeverity.length === 0 || lockedSeverity.includes(cveDetail.Severity))
    );    
  });
  filteredCveDetails.sort((a, b) => b.Impact_Score - a.Impact_Score);

  console.log(filteredCveDetails)
  
  
  
  const lockList = () => {
    setLoading(true); 
    setLockedList((prevLockedList) => [...prevLockedList, ...selectedValues]);
    setlockedSeverity((prevlockedSeverity) => [...prevlockedSeverity, ...selectedSeverities])
    setlockedImpact((prevlockedImpact) => [...prevlockedImpact, ...selectedImpactScoreRanges])
    console.log('Clicked')
  };

  const clearList = () => {
    setLockedList([]);
    setlockedSeverity([]);
    setlockedImpact([]);
    setSelectedValues([]);
    setSelectedSeverities([]);
    setCveDetails([]);
    setSelectedImpactScoreRanges([])
  };

  const handleTermClick = (term) => {
    const glossaryDefinition = glossaryData.find((item) => item.term === term);

    const dialogTitle = `Glossary Term: ${term}`;
    const dialogContent = glossaryDefinition && glossaryDefinition.definitions && glossaryDefinition.definitions[0] ?
        glossaryDefinition.definitions[0].text :
        'Glossary definition not found or is incomplete';

    setTitle(dialogTitle)
    setContent(dialogContent)
    setOpen(true)

   
};


  const glossaryIndex = {};
  glossaryData.forEach(item => {
  const lowercasedTerm = item.term.toLowerCase();
  if (!glossaryIndex[lowercasedTerm]) {
    glossaryIndex[lowercasedTerm] = item.term;
  }
});

const findMatchingGlossaryTerm = (words) => {
  const lowerCaseWords = words.map(word => word.toLowerCase());
  let matchingTerm = null;

  for (let i = words.length; i > 0; i--) {
    const partialWords = lowerCaseWords.slice(0, i);
    const combinedWord = partialWords.join(' ');

    if (glossaryIndex[combinedWord]) {
      matchingTerm = glossaryIndex[combinedWord]; 
    }
  }
  return matchingTerm;
};


  

  
  
  

const renderClickableDescription = (description) => {
  const words = description.split(' ');

  return words.map((word, index) => {
    let matchingTerm = null;

    for (let i = 1; i <= 3; i++) {
      const nextWords = words.slice(index, index + i);
      const foundTerm = findMatchingGlossaryTerm(nextWords);

      if (foundTerm) {
        matchingTerm = foundTerm;
        break;
      }
    }

    if (matchingTerm) {
      const glossaryDefinition = glossaryData.find((item) => item.term === matchingTerm);

      if (glossaryDefinition && glossaryDefinition.definitions && glossaryDefinition.definitions[0]) {
        return (
          <span key={index}>
            {index > 0 && ' '}
            <span onClick={() => handleTermClick(matchingTerm)} className="highlight">
              {word}
            </span>
          </span>
        );
      }
    }

    return <span key={index}>{index > 0 && ' ' }{word}</span>;
  });
};




  
  return (
    <>
      <h1 className="cve-heading">Threat Intelligence</h1>
      <div className="cve-container">
        <div className='keyword-section'>
          <label className='fontBigger label1'>
            Select product keywords, Then click "Submit" if you would like to try again then click "Clear" and reselect, this will also clear current results:
          </label>
        </div>
        <div className='checkbox-section'>
          <div className='keyword-section'>
            <h2 className="fontBigger">Keywords:</h2>
            <div className='keyword-checkboxes'>
              {keywords.map((keyword, index) => (
                <div className='fontBigger' key={index}>
                  <input
                    style={{ cursor: 'pointer' }}
                    type="checkbox"
                    value={keyword}
                    onChange={() => toggleKeywords(keyword)}
                    checked={isSelected(keyword)}
                  />
                  {keyword}
                </div>
              ))}
            </div>
          </div>
          <div className='severity-section'>
            <h2 className="fontBigger">Severity Levels:</h2>
            <div className="severity-checkboxes">
              {severities.map((severity, index) => (
                <div className="fontBigger" key={index}>
                  <input
                    style={{ cursor: 'pointer' }}
                    type="checkbox"
                    value={severity}
                    onChange={() => toggleSeverity(severity)}
                    checked={isSeveritySelected(severity)}
                  />
                  {severity}
                </div>
              ))}
            </div>
          </div>
          <div className='impact-section'>
            <h2 className="fontBigger">Impact Score Ranges:</h2>
            <div className="impact-score-checkboxes">
              {impactScoreRanges.map((range, index) => (
                <div className="fontBigger" key={index}>
                  <input
                    style={{ cursor: 'pointer' }}
                    type="checkbox"
                    value={range.label}
                    onChange={() => toggleImpactScoreRange(range)}
                    checked={isImpactScoreRangeSelected(range)}
                  />
                  {range.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="button-section">
          <button className="cve-dropdown" onClick={lockList}>
            <b>Submit</b>
          </button>
          <button className="cve-dropdown" onClick={clearList}>
            <b>Clear</b>
          </button>
        </div>
      </div>

      <div className="cve-info-container">
        <h2 className="cveTitle">CVE Information</h2>
        {loading && <div className="loading-spinner"></div>}
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className='buttonClose' onClick={() => setOpen(false)}>Close</button>
          </DialogActions>
        </Dialog>
        {filteredCveDetails.map((cveDetail, index) => (
          <div key={index} className="cve-item">
            <h3>CVE ID: {cveDetail.CveID}</h3>
            <p>Description: {renderClickableDescription(cveDetail.Description)}</p>
            <p>Severity: {cveDetail.Severity}</p>
            <p>Exploitability Score: {cveDetail.Exploitability_Score}</p>
            <p>Impact Score: {cveDetail.Impact_Score}</p>
            <p>Product Type: {cveDetail.Product_Type}</p>
            <hr />
          </div>
        ))}
      </div>
    </>
    
  );
};

export default CveListComponent;
