import React, { Component } from 'react';
import { auth } from '../Firebase_Config/firebase';
import { updatePassword } from "firebase/auth";
import Button from '@mui/material//Button';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center' 
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  progess: {
    position: 'absolute'
  }
});
  
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value, 
});

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordOne: '',
      passwordTwo: '',
      error: null,
      loading: false
    };
  }

  onSubmit = async (event) => {
    event.preventDefault();

    const { passwordOne } = this.state;

    try {
      this.setState({ loading: true });
      // Update password using Firebase auth
      await updatePassword(auth.currentUser, passwordOne);
      this.setState(() => ({
        passwordOne: '',
        passwordTwo: '',
        error: null,
        loading: false
      }));
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  render() {
    const {
      passwordOne,
      passwordTwo,
      error,
      loading
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '';

    const classes = styles;

    return (
      <div>
        <Container component="main" maxWidth="md" style={{ marginLeft: '0px' }}>
          <CssBaseline />
          <div className={classes.paper}>
            <form className={classes.form} noValidate onSubmit={this.onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="New Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={error ? true : false}
                    onChange={event => this.setState(byPropKey('passwordOne', event.target.value))}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm New Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="current-password"
                    onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
                  />
                </Grid>
                <Grid item xs={4} style={{ alignSelf: 'center' }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    style={{ padding: '15px', marginTop: '8px' }}
                    disabled={loading || isInvalid}
                  >
                    Reset My Password
                    {loading && <CircularProgress size={30} className={classes.progess} />}
                  </Button>
                </Grid>
              </Grid>
              {error && error.message && (
                <Typography variant="body2" style={{ color: 'red', fontSize: '0.8rem', marginTop: 10 }}>
                  {error.message}
                </Typography>
              )}
            </form>
          </div>
        </Container>
      </div>
    );
  }
}

export default PasswordChangeForm;
