import React, { useState, useEffect } from 'react';
import './Gloss.css';

const GlossaryComp = () => {
  const [glossaryData, setGlossaryData] = useState([]);
  const [filterLetter, setFilterLetter] = useState(null);
  const [originalGlossaryData, setOriginalGlossaryData] = useState([]);

  useEffect(() => {
    fetch('/glossary-export.json')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
        setGlossaryData(data.parentTerms);
        setOriginalGlossaryData(data.parentTerms);
      })
      .catch(error => console.error('Error fetching glossary data:', error));
  }, []);

  const handleFilter = letter => {
    setFilterLetter(letter);
  };

  const handleScrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleFilterBeforeA = () => {
    const firstATermIndex = originalGlossaryData.findIndex(term =>
      term.term.toLowerCase().startsWith('a')
    );

    if (firstATermIndex !== -1) {
      const filteredTerms = originalGlossaryData.slice(0, firstATermIndex);
      setFilterLetter(null);
      setGlossaryData(filteredTerms);
    }
  };

  const handleFilterAll = () => {
    setFilterLetter(null);
    setGlossaryData(originalGlossaryData);
  };

  const filteredGlossaryData = filterLetter
    ? originalGlossaryData.filter(term =>
        term.term.toLowerCase().startsWith(filterLetter.toLowerCase())
      )
    : glossaryData;

  return (
    <div>
      <h1 className='heading'>Security Glossary</h1>
      <div className='alphabet-filter'>
        <button onClick={handleFilterBeforeA}>Before A</button>
        {Array.from({ length: 26 }, (_, index) => (
          <button key={index} onClick={() => handleFilter(String.fromCharCode(65 + index))}>
            {String.fromCharCode(65 + index)}
          </button>
        ))}
        <button onClick={handleFilterAll}>All</button>
        <button className='back-to-top' onClick={handleScrollTop}>Back to Top</button>
      </div>
      <div className='glossaryTable'>
        <table>
          <thead>
            <tr>
              <th>Term</th>
              <th>Definition</th>
              <th>Source</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {filteredGlossaryData.map(term => (
              <tr key={term.id}>
                <td className='glossaryTable tdColor'>{term.term}</td>
                <td className='glossaryTable tdColor'>{term.definitions ? (term.definitions[0].text || 'Not available') : 'Not available'}</td>
                <td className='glossaryTable tdColor'>{term.definitions && term.definitions[0].sources ? (term.definitions[0].sources[0].text || 'Not available') : 'Not available'}</td>
                <td className='glossaryTable tdColor'>
                  {term.definitions && term.definitions[0].sources && term.definitions[0].sources[0].link ? (
                    <a className='SourceLink' href={term.definitions[0].sources[0].link} target="_blank" rel="noopener noreferrer">
                      {term.definitions[0].sources[0].link || 'Not available'}
                    </a>
                  ) : 'Not available'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GlossaryComp;
