import React, { useState, useEffect, useRef } from 'react';

const RiskMeter2 = (props) => {

    const score = props.score;
    let marginLeft = null;
    let color = null;
    if (score >= 32000) {
        color = '#0F1035';
        marginLeft = '42px'
    } else if (score >= 24000 && score < 32000) {
        color = '#164863';
        marginLeft = '146px';
    } else if (score >= 16000 && score < 24000) {
        marginLeft = null;
        color = '#427D9D';
    } else if (score >=8000 && score < 16000) {
        marginLeft = '357px'
        color = '#9BBEC8';
    } else {
        marginLeft = '462px'
        color = '#DDF2FD';
    }

    return(
        <div style={{marginBottom: '25px', width: '657px', height: '62px'}}>
            <div style={{display: 'flex', width: '100%', marginBottom: '6px'}}>
                <div className="drop" style={{backgroundColor: color, marginLeft: marginLeft}}></div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <div style={{backgroundColor: '#0F1035', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#164863', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#427D9D', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#9BBEC8', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#DDF2FD', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', fontSize: '11px', color: '#606060'}}><span style={{fontWeight: '600'}}>40000</span><span style={{marginLeft: '40px', color: '#8a8687'}}>{'Very High'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', fontSize: '11px', color: '#606060'}}><span style={{fontWeight: '600'}}>32000</span><span style={{marginLeft: '40px', color: '#8a8687'}}>{'High'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', fontSize: '11px', color: '#606060'}}><span style={{fontWeight: '600'}}>24000</span><span style={{marginLeft: '20px', color: '#8a8687'}}>{'Moderate'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', fontSize: '11px', color: '#606060'}}><span style={{fontWeight: '600'}}>16000</span><span style={{marginLeft: '50px', color: '#8a8687'}}>{'Low'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', fontSize: '11px', color: '#606060'}}><span style={{fontWeight: '600'}}>8000</span><span style={{marginLeft: '7px', color: '#8a8687'}}>{'Very Low'}</span><span style={{marginLeft:  '20px', color: '#606060'}}>0</span></div>
            </div>
        </div>
    )
};
export default RiskMeter2;



