import React, { useState, useEffect, useRef } from 'react';
import './RiskMeter.css'




const RiskMeter = (props) => {

    const score = props.score;
    let marginLeft = null;
    let color = null;
    if (score >= 46.948) {
        color = '#0F1035';
        marginLeft = 'var(--marginLeft-large)';
    } else if (score >= 21.053 && score < 46.948) {
        color = '#164863';
        marginLeft = 'var(--marginLeft-medium)';
    } else if (score >= 9.036 && score < 21.053) {
        color = '#427D9D';
        marginLeft = null;
    } else if (score >=3.718 && score < 9.036) {
        color = '#9BBEC8';
        marginLeft = 'var(--marginLeft-small)';
    } else {
        color = '#DDF2FD';
        marginLeft = 'var(--marginLeft-extraSmall)';
    }
    
    

    return(
        <div className="risk-meter-container">
            <div style={{display: 'flex', width: '100%', marginBottom: '6px'}}>
                <div className="drop" style={{backgroundColor: color, marginLeft: marginLeft}}></div>
            </div>
            <div className="legend">
                <div style={{backgroundColor: '#0F1035', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#164863', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#427D9D', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#9BBEC8', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
                <div style={{backgroundColor: '#DDF2FD', marginLeft: '0.25rem', width: '100%', height: '17.5px'}}></div>
            </div>
            <div className="legend">
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', color: '#606060'}}><span style={{fontWeight: '600'}}>100</span><span style={{marginLeft: '40px', color: '#8a8687'}}>{'Very High'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', color: '#606060'}}><span style={{fontWeight: '600'}}>46.948</span><span style={{marginLeft: '40px', color: '#8a8687'}}>{'High'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', color: '#606060'}}><span style={{fontWeight: '600'}}>21.053</span><span style={{marginLeft: '20px', color: '#8a8687'}}>{'Moderate'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', color: '#606060'}}><span style={{fontWeight: '600'}}>9.036</span><span style={{marginLeft: '50px', color: '#8a8687'}}>{'Low'}</span></div>
                <div style={{marginLeft: '-0.25rem', width: '100%', height: '17.5px', color: '#606060'}}><span style={{fontWeight: '600'}}>3.718</span><span style={{marginLeft: '7px', color: '#8a8687'}}>{'Very Low'}</span><span style={{marginLeft:  '20px', color: '#606060'}}>0</span></div>
            </div>
        </div>
    )
};
export default RiskMeter;



