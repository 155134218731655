


export const Private = () => {
    return(
        <section>
            <h2>Private</h2>
        </section> 
    )
   
}