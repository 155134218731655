import React from 'react';
import './self-assessment.css'
import { Link } from 'react-router-dom';


function SelfAssessmentStart() {
    return (
        <div>
            <h1 className='p1'>Welcome to the Self-Assessment</h1>
            <div className='containerAssessmentStart'>  
            
                <div className='box'>
                    <h3 className='p1' > How compliant are you with Cyber Essentials and what is the current level of Cyber Risk for your Organisation</h3>
                    <p className='p4'>The tool will evaluate and quantify an organisation's vulnerabilities and potential threats in the digital landscape. This tool systematically analyses the security infrastructure, identifying weak points and potential entryways for cyber threats.</p>
                    <p className='p4'>It assesses factors such as data encryption, network configurations, access controls, and overall system resilience. By employing advanced algorithms and threat intelligence, the tool provides a comprehensive evaluation of an organisation's cyber risk posture.</p>
                    <p className='p4'>The results generated by the Cyber Risk Assessment Tool offer actionable insights and recommendations, empowering businesses to proactively enhance their cybersecurity measures, mitigate potential risks, and safeguard sensitive information. This invaluable resource aids in making informed decisions to fortify digital defences, ultimately fostering a resilient and secure online environment for the organisation.</p>
                    <p className='p2'>V1</p>
                </div>
                <div className='box'>
                    <h3 className='p1'>Assess your organisation’s cybersecurity implementation against the requirements and recommendations listed.</h3>
                    <p className='p5'>Requirements are denoted by the use of the word <b>“shall”</b>, and these need to be implemented in the organisation in order to qualify for Cyber Essentials certification.</p>
                    <p className='p5'>Recommendations are denoted by the use of the word <b>“should”</b>, and whilst organisations are encouraged to implement these recommendations, these are not compulsory to qualify for Cyber Essentials certification.</p>
                    <p className='p5'><b>For each requirement and recommendation, indicate:</b></p>
                    <ul className='p5' >
                        <li><b>“Yes”: </b>If the measure described in the statement is implemented in your organisation;</li>
                        <li><b>“No”:</b> If the measure described in the statement is not implemented in your organisation; or</li>
                        <li><b>“Not applicable”</b>: If the measure described in the statement is not applicable.</li>
                    </ul>
                    <p className='p5'>For recommendations that are “Not applicable”, fill in remarks to explain why this is not applicable.</p>
                    <p className='p5'>The template lists “suggested artefacts” for each requirement and recommendation. You would need to prepare these as supporting documents to be submitted to your certification body subsequently. Only if selecting 'Yes'</p>
                    <br></br>
                    <h3 className='p2'>To begin the Self-Assessment click the start button below:</h3>
                </div>
            </div> 
            <div className='button-container'>
                    <Link style={{textDecoration: 'none', color:'white'}}to={'/SelfAssessment'}>
                    <button className='buttonStart'>
                        <img className='buttonImg' src="Start_button.png" alt="Start" />
                    </button>
                    </Link>
                </div>
      </div>
    );
  }
  
  export default SelfAssessmentStart;