import { createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";
import { useState } from "react"
import { auth } from "../Firebase_Config/firebase";
import { Navigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { collection, addDoc,} from 'firebase/firestore';
import { db } from '../Firebase_Config/firebase';
import 'bootstrap/dist/css/bootstrap.min.css';
import './SignUp.css';
export const SignUp = ({user}) => {

    const initialFormState = {
        email: '',
        password: '',
        confirmPassword: '',
        OrganName: '',
        ACRA_Num: '',
        turnover: '',
        NumOfEmplyo: '',
        sector: '',
    };

     
    const questions = [
  
      {text: 'Does your organisation have a user administration process for new employees and leaving employees?'},
      {text: 'Does your organisation review user privileges?'},
      {text: 'Are complex passwords enforced within the organisation?'},
      {text: 'Do employees, individuals or third parties have remote access to your network?'},
      {text: 'Does your organisation provide security awareness training?'},
      {text: 'Are the laptops/desktops of employees encrypted?'},
      {text: 'Are backups stored remotely, and if so are they properly protected?'},
      {text: 'Are patches installed on a timely basis?'},
      {text: 'Does your organisation have a wireless corporate network?'},
      {text: 'Does your organisation have a wireless guest network?'},
      {text: 'Does your organisation have security & privacy policies?'},
      {text: 'Does your organisation have an incident response and recovery plan?'},
      {text: 'Does your organisation have an anti-virus?'},
      {text: 'Does your organisation protect its environment with a firewall?'},
      {text: 'Does your organisation perform internal vulnerability scans?'},
      {text: 'Was your organisation in the past year a victim of a cyber attack?'}
    ]
    
    
    const [email, setEmail] =useState('')
    const [password, setPassword] =useState('')
    const [isSignUpActive, setIsSignUpActive] =useState(true);
    const [error, setError] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState(Array(questions.length).fill(null));
    const [formattedAnswers, setFormattedAnswers] = useState([]);
    const [frequencies, setFrequencies] = useState({ yes: 0, no: 0 }); 
    const [riskScore, setRiskScore] = useState();
    const [isQuestionnaireActive, setIsQuestionnaireActive] = useState(false); 
    const [showSignUp, setShowSignUp] = useState(true); 
    const [formData, setFormData] = useState(initialFormState);

    const handleMethodChange = () => {
        setIsSignUpActive(!isSignUpActive)
    } 

    const handleQuestionChange = () => {
        // Check if all required fields are filled out
        if (
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.OrganName ||
            !formData.ACRA_Num ||
            !formData.turnover ||
            !formData.NumOfEmplyo ||
            !formData.sector
        ) {
            setError("Please fill out all required fields.");
            return;
        }
    
        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
    
        // Clear any previous error messages
        setError(null);
    
        // Proceed to the questionnaire section
        setShowSignUp(!showSignUp);
        setIsQuestionnaireActive(!isQuestionnaireActive);
    };
    
    const MAX_RETRY_ATTEMPTS = 3;
   

    const handleSignUp = async () => {
        // Validate form data
        if (!formData.email || !formData.password || !formData.confirmPassword ||
            !formData.OrganName || !formData.ACRA_Num || !formData.turnover ||
            !formData.NumOfEmplyo || !formData.sector) {
            setError("Please fill out all required fields.");
            return;
        }
    
        // Check if passwords match
        if (formData.password !== formData.confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        let retryAttempts = 0;
        while (retryAttempts < MAX_RETRY_ATTEMPTS) {
            try {
                const unansweredQuestionIndex = answers.findIndex((answer) => answer === null);
                if (unansweredQuestionIndex !== -1) {
                    setError(`Please answer question ${unansweredQuestionIndex + 1}`);
                    return
                } else {
                        
                        const noCount = answers.filter(answer => answer === 'no').length;
                        const riskScore = noCount * 6.25;
                        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
                        const user = userCredential.user;
                        const sectorScore = calculateSectorScore(formData.sector);
                        const employeeScore = calculateEmployeeScore(parseInt(formData.NumOfEmplyo ));
                        const formattedAnswers = calculatedFormattedAnswers
                        const frequencies = calculatedFrequencies
                        
                        // Add user data to Firestore
                        const timestamp = new Date().toLocaleString();
                        const userRef = collection(db, "users");
                        const docRef = await addDoc(userRef, {
                            userId: user.uid,
                            email: formData.email,
                            OrganName: formData.OrganName,
                            ACRA_Num: formData.ACRA_Num,
                            turnover: formData.turnover,
                            NumOfEmplyo: formData.NumOfEmplyo,
                            sector: formData.sector,
                            Answers: formattedAnswers,
                            Frequencies: frequencies,
                            Risk_Score: riskScore,
                            Sector_Score: sectorScore,
                            Employee_Score: employeeScore,
                            isAdmin: false,
                            isAssessmentComplete: false,
                            timestamp
                        });
                        
                        if (docRef.id) {
                            setFormData(initialFormState);
                            setFormattedAnswers([]);
                            setFrequencies({ yes: 0, no: 0 });
                            setRiskScore(null);
                            console.log('User has been added successfully');
                            window.location.href = '/SelfAssessmentStart';
                        } else {
                            retryAttempts++;
                            console.log(`Retry attempt ${retryAttempts}`);
                        }
                    }
            } catch (error) {
                // Handle errors
                console.error("Error signing up:", error);
                setError(error.message);
                 return;
            }
        }
        setError("Failed to create account. Please contact the  Adminsitrator at info@assentian.com");
    };
    

    const handleSignIn = () => {
        if (!formData.email || !formData.password) return;
        signInWithEmailAndPassword(auth, formData.email, formData.password)
        .then((userCredntial)=> {
            const user = userCredntial.user
            console.log(user);
            window.location.href = '/SelfAssessmentStart';
        })
        .catch((error) => {
            const errorCode= error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            setError(errorMessage);
        }) 
    }


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'turnover' || name === 'NumOfEmplyo') {
            // Check if value is a number
            if (isNaN(value)) {
                setError("Please enter a valid number for turnover and number of employees.");
                return;
            }
        }
        setError(null)
        setFormData({ ...formData, [name]: value });
    };
    
    
  
    
  
    const handleAnswer = (answer) => {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
    };
  
    const handleNext = () => {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    };
  
    const handlePrev = () => {
      if (currentQuestionIndex > 0) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
    };
  


      
           

    const calculatedFormattedAnswers = answers.map((answer, index) => ({
        question: questions[index].text,
        answer: answer,
    }));
    const calculatedFrequencies = answers.reduce(
        (acc, answer) => {
        acc[answer]++;
        return acc;
        },
        { yes: 0, no: 0 }
    );

    
   

    const calculateSectorScore = (selectedSector) => {
        let sectorScore = 0;
        switch (selectedSector) {
            case "Manufacturing":
                sectorScore = 24.80;
                break;
            case "Finance and insurance":
                sectorScore = 18.90;
                break;
            case "Professional, business, and consumer services":
                sectorScore = 14.60;
                break;
           case "Energy":
                sectorScore = 10.70;
                break;
            case "Retail and wholesale":
                sectorScore = 8.70;
                break;
            case "Education":
                sectorScore = 7.30;
                break;
            case "Healthcare":
                sectorScore = 5.80;
                break;
            case "Government":
                sectorScore = 4.80;
                break;
            case "Transportation":
                sectorScore = 3.90;
                break;
            case "Media and telecom":
                sectorScore = 0.50;
                break;
            case "Other":
                sectorScore = 10.10;
                break;
            default:
                sectorScore = 0;
                break;
        }
        return sectorScore;
    };

    const calculateEmployeeScore = (numOfEmployees) => {
        if (numOfEmployees >= 1000) {
            return 70;
        } else {
            return 43; 
        }
    };
    


    return (
        <div className="allignBoth">
            {showSignUp && 
                <div className={`SignSection ${isSignUpActive ? 'SignUpActive' : 'LoginActive'}`}>
                    <form>
                        <h3 className="Title_legend"> Cyber Maturity Assessment Service</h3>
                        {isSignUpActive && <legend className="Title_legend">Sign Up</legend>}
                        {!isSignUpActive && <legend className="Title_legend">Login</legend>}
                        {isSignUpActive && 
                            <div>
                                <p className="centerText"> Already have an Account? <button type="button" className='buttonNext' onClick={handleMethodChange}>Sign In</button></p> 
                            </div>
                        } 
                        {error && <Alert variant="danger">{error}</Alert>}
                        <fieldset className="Sign_fieldset">
                            <div className="left-half">
                                {isSignUpActive && (
                                    <>
                                        <p>
                                            <label className="Sign_label" htmlFor="OrganName">Organisation Name*</label>
                                            <input className="Sign_input" type="text" id="OrganName" name="OrganName" value={formData.OrganName} onChange={handleInputChange} placeholder="Organisation Name" />
                                        </p>
                                        <p>
                                            <label className="Sign_label" htmlFor="ACRA_Num">ACRA Number/Unique Entity Number (UEN)*</label>
                                            <input className="Sign_input" type="text" id="ACRA_Num" name="ACRA_Num" value={formData.ACRA_Num} onChange={handleInputChange} placeholder="ACRA Number/Unique Entity Number (UEN)" />
                                        </p>
                                        <p>
                                            <label className="Sign_label" htmlFor="turnover">Annual Turnover*</label>
                                            <input className="Sign_input" type="text" id="turnover" name="turnover" value={formData.turnover} onChange={handleInputChange} placeholder="Annual Turnover" />
                                        </p>
                                        <p>
                                            <label className="Sign_label" htmlFor="NumOfEmplyo">Number of Employees*</label>
                                            <input className="Sign_input" type="text" id="NumOfEmplyo" name="NumOfEmplyo" value={formData.NumOfEmplyo} onChange={handleInputChange} placeholder="Number of Employees" />
                                        </p>
                                    </>
                                )}
                            </div>
                            <div className="right-half">
                                {isSignUpActive && (
                                    <>
                                        <p>
                                            <label className="Sign_label" htmlFor="sector">Sector*</label>
                                            <select className="Sign_input" id="sector" name="sector" value={formData.sector} onChange={handleInputChange} placeholder="Select Sector">
                                                <option value="">Select Sector</option>
                                                <option value="Manufacturing">Manufacturing</option>
                                                <option value="Finance and insurance">Finance and insurance</option>
                                                <option value="Professional, business, and consumer services">Professional, business, and consumer services</option>
                                                <option value="Retail and wholesale">Retail and wholesale</option>
                                                <option value="Healthcare">Healthcare</option>
                                                <option value="Government">Government</option>
                                                <option value="Transportation">Transportation</option>
                                                <option value="Media and telecom">Media and telecom</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </p>
                                    </> 
                                )}

                                <p>
                                    <label className="Sign_label" htmlFor="email">Email*</label>
                                    <input className="Sign_input" type="text" id='email' name="email" value={formData.email} onChange={handleInputChange} placeholder="Email" />
                                </p>
                                <p>
                                    <label className="Sign_label" htmlFor="password">Password*</label>
                                    <input className="Sign_input" type="password" id='password' name="password" value={formData.password} onChange={handleInputChange} placeholder="Password" />
                                </p>
                                {isSignUpActive && (
                                    <p>
                                        <label className="Sign_label" htmlFor="confirmPassword">Confirm Password*</label>
                                        <input className="Sign_input" type="password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} placeholder="Confirm Password" />
                                    </p>
                                )}
                                    
                            
                            </div>
                            {isSignUpActive && <button className="buttonSign" type="button" onClick={handleQuestionChange}>Next</button>}
                            {!isSignUpActive && <button className="buttonSign" type="button" onClick={handleSignIn}>Login</button>}
                        </fieldset>
                        <br></br>
                        {!isSignUpActive && <p>Don't have an account? <button className='buttonNext' onClick={handleMethodChange}>Sign Up</button></p>}
                        <p>Forgot your Password? <Link style={{ textDecoration: 'none', color: 'white' }} to={'/ForgotPassword'}><button className='buttonNext'>Click Here</button></Link></p>
                    </form>
                </div>
            }
            {isQuestionnaireActive &&
                    <>
                    <div className="questionsContainer">
                        {error && <Alert variant="danger">{error}</Alert>}
                        <div className="navigation-buttons">
                            <button className="buttonNext" onClick={handleQuestionChange}>Back</button>
                        </div>
                        <div>
                            <h2>Question {currentQuestionIndex + 1}</h2>
                            <p className="sixteenQuestionTxt">{questions[currentQuestionIndex].text}</p>
                            <form className="radioContainer">
                                <label>
                                <input
                                    type="radio"
                                    name={`answer-${currentQuestionIndex}`}
                                    value="yes"
                                    checked={answers[currentQuestionIndex] === 'yes'}
                                    onChange={() => handleAnswer('yes')}
                                />
                                <span className="radioText">Yes</span>
                                </label>
                                <label>
                                <input
                                    type="radio"
                                    name={`answer-${currentQuestionIndex}`}
                                    value="no"
                                    checked={answers[currentQuestionIndex] === 'no'}
                                    onChange={() => handleAnswer('no')}
                                />
                                <span className="radioText">No</span>
                                </label>
                            </form>
                            </div>
                            <div className="navigation-buttons">
                            {currentQuestionIndex > 0 && <button className='buttonNext' onClick={handlePrev}>Previous</button>}
                            {currentQuestionIndex === questions.length - 1 ? (
                            <button className="buttonNext" onClick={handleSignUp}>
                               Sign Up
                            </button>
                            ) : (
                                <button className="buttonNext" onClick={handleNext}>
                                    Next
                                </button>
                            )}
                            
                        </div>
                    </div>
                    </>
                }
        </div>
        
    )
   
}
