import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { CardContent, CardActions, Card } from '@mui/material';
import { db, auth } from '../Firebase_Config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './admin.css';

const AdminDashboard = () => {
    const [users, setUsers] = useState([]);
    const [adminTrue, setAdminTrue] = useState(null);

    useEffect(() => {
        const fetchAdminAndUsers = async () => {
            try {
                const userId = getUserId();

                // Fetch admin status
                const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
                const userDocSnapshot = await getDocs(userDocQuery);

                if (!userDocSnapshot.empty) {
                    const userDocData = userDocSnapshot.docs[0].data();
                    const isAdminTrue = userDocData.isAdmin || null;
                    setAdminTrue(isAdminTrue);
                } else {
                    console.log('User document not found for user ID:', userId);
                }

                // Fetch non-admin users
                const usersRef = collection(db, 'users');
                const q = query(usersRef, where('isAdmin', '==', false));
                const querySnapshot = await getDocs(q);

                const fetchedUsers = [];
                querySnapshot.forEach(doc => {
                    fetchedUsers.push({ id: doc.id, ...doc.data() });
                });
                setUsers(fetchedUsers);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchAdminAndUsers();
    }, []);

    const getUserId = () => {
        const user = auth.currentUser;
        return user.uid;
    };

    return (
        <div>
            {adminTrue ? (
                <div>
                    <h3 className='textCentre'>Administrator Dashboard</h3>
                    <div>
                        <div className=' textCentre'>
                            <h5>List of Users</h5>
                        </div>
                    </div>
                    <div className="cardContainer">
                        {users.map(user => (
                            user.isAssessmentComplete && (
                                <Card className='card' key={user.id} elevation={10}>
                                    <CardContent>
                                        <p className='textCentre bigger'>
                                            {user.OrganName}
                                        </p>
                                    </CardContent>
                                    <CardActions>
                                        <Link to={{ pathname: "/AdminAssessmentDashboard", search: `?id=${user.userId}` }}>
                                            <button className='AssessmentDashButton' size="small">
                                                {'Assessment Dashboard'}
                                            </button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            )
                        ))}
                    </div>
                </div>
            ) : (
                <h1 className='textCentre'>You do not have Admin Permissions</h1>
            )}
        </div>
    );
};

export default AdminDashboard;
