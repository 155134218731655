
import CveListComponent from './ThreatComp';
import React, { useState, useEffect } from 'react';


function ThreatIntel() {
  const [glossaryData, setGlossaryData] = useState([]);
 
  useEffect(() => {
    fetch('/glossary-export.json')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data); 
        setGlossaryData(data.parentTerms);
      })
      .catch(error => console.error('Error fetching glossary data:', error));
  }, []);


    return (
      <div className="body">
        <CveListComponent glossaryData={glossaryData}/>
      </div>
    );
  }
  
  export default ThreatIntel;