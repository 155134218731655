import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AssessmentIcon from '@mui/icons-material/Assessment';
import QuizIcon from '@mui/icons-material/Quiz';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import SecurityIcon from '@mui/icons-material/Security';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {Link} from 'react-router-dom';
import { signOut } from "firebase/auth"
import { db, auth } from '../Firebase_Config/firebase';
import { useEffect } from 'react';
import './NavBar.css'
import { collection, query, where, getDocs,  } from 'firebase/firestore';







function ResponsiveAppBar({user, handelSignOut}) {

  
  useEffect(() => {
      const fetchDash = async () => {
        try {
            const userId = getUserId();
            console.log('User ID:', userId); // Add this line for debugging

            // Fetch risk score
            const userDocQuery = query(collection(db, 'users'), where('userId', '==', userId));
            const userDocSnapshot = await getDocs(userDocQuery);

            if (!userDocSnapshot.empty) {
                const userDocRef = userDocSnapshot.docs[0].ref;
                const userDocData = userDocSnapshot.docs[0].data();
                const isAssComplete = userDocData.isAssessmentComplete || null;
                const isAdminTrue = userDocData.isAdmin || null;
                setDashboardOpen(isAssComplete)
                setAdminTrue(isAdminTrue)
                console.log(dashboardOpen)
                console.log(adminTrue)

            } else {
                console.log('User document not found for user ID:', userId);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchDash()
    setAnchorEl(null);
    setAnchorElNav(null);
    setAnchorElUser(null);
  }, [user]);
  
 
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dashboardOpen, setDashboardOpen] = React.useState(null)
  const [adminTrue, setAdminTrue] = React.useState(null)
  
  const getUserId = () => {
    const user = auth.currentUser;
    return user.uid;
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut =() => {
    signOut(auth)
    .then(() => console.log('Sign Out'))
    .catch((error) => console.log(error));
}


  return (
    <AppBar position="static" style={{backgroundImage: 'url(blubg.jpg)', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginBottom: '15px'}}> 
      <Container maxWidth="l">
          {user ? (
            <Toolbar style={{minHeight: '75px'}} disableGutters>
              <Link style={{textDecoration: 'none', color:'white', display: 'flex', alignItems: 'center'}} to={'/'}>
                <img style={{height: '40px', width: '200px' }} src="logo1.png" alt="Assentian Logo" />
              </Link>
              <Box sx={{ flexGrow: 1 }} />
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  sx={{
                    '&:hover': {
                      color: '#0163b8', 
                    },
                  }}
                >
                  <AssessmentIcon />
                  <Typography sx={{marginRight: 5, fontSize: 20, '&:hover': {color: '#0163b8'}}}>
                      Menu
                  </Typography>
                </IconButton>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <Link style={{textDecoration: 'none', color:'#10549c'}} to={'/SelfAssessmentStart'}>
                    <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} onClick={handleClose}>
                      <QuizIcon/>Self-Assessment
                    </MenuItem>   
                  </Link>
                  {dashboardOpen && 
                    <Link style={{textDecoration: 'none', color:'#10549c'}} to={'/Dashboard'}>
                      <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} onClick={handleClose}>
                        <DashboardIcon/>Dashboard
                      </MenuItem> 
                    </Link>
                  }
                  <Link style={{textDecoration: 'none', color:'#10549c'}} to={'/Glossary'}>
                    <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} onClick={handleClose}>
                        <ListIcon/>Glossary
                    </MenuItem>
                  </Link>
                  <Link style={{textDecoration: 'none', color:'#10549c'}} to={'/ThreatIntelligence'}>
                    <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} onClick={handleClose}>                        
                        <SecurityIcon/>Threat Intelligence
                    </MenuItem>
                  </Link>
                  <Link style={{textDecoration: 'none', color:'#10549c'}} to={'/AiTextGen'}>
                    <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} onClick={handleClose}>                      
                      <AutoAwesomeIcon/>Cyber Security Co-Pilot                      
                    </MenuItem>
                  </Link>
                </Menu>
              </div>

              <Box sx={{ flexGrow: 0 }}>
                <Box sx={{ flexGrow: 0 }}>
                  <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                      <Avatar  sx={{'&:hover': {filter: 'brightness(70%)',}}} alt={user.email ? user.email.charAt(0).toUpperCase() : ""} src="/static/images/avatar/2.jpg" />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    <Link style={{textDecoration: 'none', color:'#10549c'}}to={'/AccountManagement'}>
                      <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} onClick={handleCloseUserMenu}>                        
                          <AccountCircleIcon/>Account Management                      
                      </MenuItem>   
                    </Link>
                    {adminTrue &&
                      <Link style={{textDecoration: 'none', color:'#10549c'}}to={'/AdminDashboard'}>
                        <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} onClick={handleCloseUserMenu}>                          
                          <AdminPanelSettingsIcon/>Admin Dashboard                        
                        </MenuItem> 
                      </Link>
                    }
                    <MenuItem sx={{'&:hover': {backgroundColor: '#cad1db',},}} style={{textDecoration: 'none', color:'#10549c' }} onClick={handleSignOut}>
                        <LogoutIcon/>Sign Out
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </Toolbar>
          ) : (
            <Toolbar style={{minHeight: '75px'}} disableGutters>
              <Link style={{textDecoration: 'none', color:'white', display: 'flex', alignItems: 'center'}} to={'/'}>
                <img style={{height: '40px', width: '200px' }} src="logo1.png" alt="Assentian Logo" />
              </Link>
              
              <Box sx={{ flexGrow: 1 }} />
              <div>
                <Link className='hover-underline' to={'/SignUp_In'} >
                  <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                    >
                      <LoginIcon/>
                      <Typography sx={{marginRight: 5, fontSize: 16,}}>
                          Sign In
                      </Typography>
                    </IconButton>
                </Link>
              </div>  
            </Toolbar>
          )}
      </Container>
    </AppBar>
  );

}

export default ResponsiveAppBar;
