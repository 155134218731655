import './App.css';
import { Route, Routes} from 'react-router-dom'
import ResponsiveAppBar from './NavAppbar/NavBar';
import Opening from './Opening Page/Opening';
import Footer from './Footer/Footer';
import GlossaryComp from './Glossary/glossaryComp';
import SelfAssessmentStart from './Self-Assessment/self-assessment-start';
import SelfAssessment from './Self-Assessment/self-assessment';
import { SignUp } from './Auth/SignUp';
import { Private } from './Auth/private';
import { onAuthStateChanged } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { auth } from './Firebase_Config/firebase';
import { ProtectedRoutes } from './Auth/ProtectedRoutes';
import AccountManage from './AccountManagement/AccountManage';
import ForgotPassword from './AccountManagement/ForgotPass';
import Dashboard from './Dashboard/dashboardMain';
import DashGraphs from './Dashboard/dashboardGraphs';
import ThreatIntel from './Threat/Threat_intel';
import AdminDashboard from './AdminDashboard/AdminDashboard';
import AdminAssessmentDashboard from './AdminDashboard/AdminAssessmentDashboard';
import CohereInterface from './AIChat/AiTextGen';



function App() {
  const [user, setUser] =useState(null);
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user) {
        setUser(user)
        setIsFetching(false)
        return;
      }
      setUser(null);
      setIsFetching(false)
    })
    return () => unsubscribe();
  }, [])

  if(isFetching){
    return <h2>Loading...</h2>
  }
  return (
      <div>
      <ResponsiveAppBar user={user}  />
          <Routes>
            <Route path='/' element={<Opening/>}/>
            <Route path='/SignUp_In' element={<SignUp user={user}/>}/>
            <Route path='/ForgotPassword' element={<ForgotPassword user={user}/>}/>
            <Route path='/Glossary' element={<ProtectedRoutes user={user}><GlossaryComp/></ProtectedRoutes>}/>
            <Route path='/SelfAssessmentStart' element={<ProtectedRoutes user={user}><SelfAssessmentStart/></ProtectedRoutes>}/>
            <Route path='/SelfAssessment' element={<ProtectedRoutes user={user}><SelfAssessment/></ProtectedRoutes>}/>
            <Route path='/AccountManagement' element={<ProtectedRoutes user={user}><AccountManage/></ProtectedRoutes>}/>
            <Route path='/Dashboard' element={<ProtectedRoutes user={user}><Dashboard/></ProtectedRoutes>}/>
            <Route path='/DashboardGraphs' element={<ProtectedRoutes user={user}><DashGraphs/></ProtectedRoutes>}/>
            <Route path='/ThreatIntelligence' element={<ProtectedRoutes user={user}><ThreatIntel/></ProtectedRoutes>}/>
            <Route path='/AdminDashboard' element={<ProtectedRoutes user={user}><AdminDashboard/></ProtectedRoutes>}/>
            <Route path='/AdminAssessmentDashboard' element={<ProtectedRoutes user={user}><AdminAssessmentDashboard/></ProtectedRoutes>}/>
            <Route path='/AiTextGen' element={<ProtectedRoutes user={user}><CohereInterface/></ProtectedRoutes>}/>
            
            <Route path='/private' element={<ProtectedRoutes user={user}><Private/></ProtectedRoutes>}/>
            
            
          </Routes>
      <Footer/>
      </div>
  );
}

export default App;
