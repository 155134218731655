import React from 'react';
import './Opening.css'

function Opening() {
    return (

        <div>
            <div className="content">
                <h3 className='other3'>Cyber Security Maturity Assessment</h3>
                <h3 className='other'> How compliant are you with Cyber Essentials and what is the current level of Cyber Risk for your Organisation</h3>
                <p className='other'>The tool will evaluate and quantify an organisation's vulnerabilities and potential threats in the digital landscape. This tool systematically analyses the security infrastructure, identifying weak points and potential entryways for cyber threats.</p>
                <p className='other2'>It assesses factors such as data encryption, network configurations, access controls, and overall system resilience. By employing advanced algorithms and threat intelligence, the tool provides a comprehensive evaluation of an organisation's cyber risk posture.</p>
                <p className='other2'>The results generated by the Cyber Risk Assessment Tool offer actionable insights and recommendations, empowering businesses to proactively enhance their cybersecurity measures, mitigate potential risks, and safeguard sensitive information. This invaluable resource aids in making informed decisions to fortify digital defences, ultimately fostering a resilient and secure online environment for the organisation.</p>
                <p className='other'><b>If this is your first visit </b>, click on the <i>Sign In</i> tab at the top of the page and then choose <i>Sign Up</i>.</p>
                <p className='other'>V1</p>
            </div>     
        </div>
           
    );
  }
  
  export default Opening;