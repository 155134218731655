import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const Footer = () => {
  React.useEffect(() => {
    const handleScroll = () => {
      // Disable scrolling if the content does not exceed the viewport
      if (document.body.scrollHeight <= window.innerHeight) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'visible';
      }
    };

    window.addEventListener('resize', handleScroll);
    handleScroll(); // Call initially to handle the initial state

    return () => {
      window.removeEventListener('resize', handleScroll);
    };
  }, []);

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="sticky" style={{ top: 'auto', bottom: 0, width: '100%', zIndex: 1000, background: 'transparent', marginTop: 'auto' }}>
        <Container maxWidth="md">
          <Toolbar>
            <Typography variant="body1" color="#063d51" style={{ textAlign: 'center', width: '100%' }}>
              &copy; {"Copyright"}, {new Date().getFullYear()} Assentian. {"All Rights Reserved"}
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Footer;
