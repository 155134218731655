import React, { useState, useEffect } from "react";
import { CohereClient } from "cohere-ai";
import "./AiTextGen.css"; // Import CSS file for styling

function CohereInterface() {
  const [inputText, setInputText] = useState("");
  const [titleText, setTitleText] = useState("");
  const [outputText, setOutputText] = useState("");
  const [chatBubbles, setChatBubbles] = useState([]);

  useEffect(() => {
    if (outputText) {
      // Simulate typing effect
      let index = 0;
      const interval = setInterval(() => {
        setChatBubbles((prevBubbles) => [
          ...prevBubbles.slice(0, -1), // Remove the previous typing effect
          {
            id: Date.now(),
            text: outputText.substring(0, index + 1),
          },
        ]);
        index++;
        if (index === outputText.length) clearInterval(interval);
      }, 20);
      return () => clearInterval(interval);
    }
  }, [outputText]);

  const generateResponse = async () => {
    const cohere = new CohereClient({
        token: '5VffqzDEM7mud1FBRgiLmoOfVx77RQJYKPgP6FT7'
    });

    try {
      const response = await cohere.generate({
        model: "command-light",
        prompt: inputText,
        maxTokens: 500,
        temperature: 0.9,
        k: 0,
        stopSequences: [],
        returnLikelihoods: "NONE",
      });
      setOutputText(response.generations[0].text);
      setTitleText(inputText)
      setInputText("");
    } catch (error) {
      console.error("Error generating response:", error);
      setOutputText("Error generating response. Please try again.");
    }
  };

  return (
    <>
      <div className="aiMainContain">
          <h1 className="aiHeading">Cyber Security Co-Pilot</h1>
      </div>
      <div className="aiContainer">
        <div className="input-container">
          <input className="promptInput"
            type="text"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Ask your security question"
          />
          <button className="aibutton" onClick={generateResponse} >  
              <svg height="24" width="24" fill="#FFFFFF" viewBox="0 0 24 24" data-name="Layer 1" id="Layer_1" class="sparkle">
                <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
              </svg>
              <span className="aitext">Answer</span></button>
        </div>
        <div id="output-box">
          {chatBubbles.map((bubble) => (
            <div key={bubble.id} className="chat-bubble">
              <p className="aiTitle">{titleText}</p>
              <p>{bubble.text}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default CohereInterface;
