import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../Firebase_Config/firebase";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import './AccountMang.css'

function ForgotPassword() {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
            .then(() => {
                alert("Check your email for password reset instructions.");
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    return (
        <div>
            <br />
            <section className="ContainerBox">
                <h1 className="forgotTitle">Forgot Password</h1>
                <div>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Enter Email Here...."
                                    name="email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <button type="submit" className="buttonForgot">Reset</button>
                    </form>
                </div>
            </section>
        </div>
    );
}

export default ForgotPassword;
