import React, { useState } from 'react';
import './dashgraphs.css';
import { Link } from 'react-router-dom';

const DashGraphs = () => {
  const [selectedButton, setSelectedButton] = useState('button1');

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const graphImages = {
    button1: { src: './Singapore Graphs/Graph1.png', title: 'Types of security event experienced in the past 12 months (% of all businesses experiencing each type of attack)', displayName: 'Security events' },
    button2: { src: './Singapore Graphs/Graph2.png', title: 'The Breakdown of an average finacial impact of a data breach', displayName: 'Financial Impact Large (LBs)' },
    button3: { src: './Singapore Graphs/Graph3.png', title: 'Average Financial Impact to Small and Medium Size Businesses (SMBs)', displayName: 'Financial Impact (SMBs)' },
    button4: { src: './Singapore Graphs/Graph4.png', title: 'Cost to Small and Medium Size Business by Attack Type', displayName: 'Cost to (SMBs)' },
    button5: { src: './Singapore Graphs/Graph5.png', title: 'Financial Impact Over time to SMBs – time to detection', displayName: 'Financial Impact Over time' },
    button6: { src: './Singapore Graphs/Graph6.png', title: 'Proportion of Businesses that have experienced a Cyber Attack', displayName: 'Cyber Attack Experiences' },
    button7: { src: './Singapore Graphs/Graph7.png', title: 'Cyber Security Concerns and Impact of Actual Incidents', displayName: 'Cyber Security Concerns' },
    button8: { src: './Singapore Graphs/Graph8.png', title: 'Cause of Reported Incidents', displayName: 'Reported Incidents' },
    button9: { src: './Singapore Graphs/Graph9.png', title: 'Likelihood of Attack by Sector', displayName: 'Attack by Sector'  },
    button10: { src: './Singapore Graphs/Graph10.png', title: 'Causes of Cyber Incidents', displayName: 'Causes of Cyber Incidents' },
    button11: { src: './Singapore Graphs/Graph11.png', title: 'Impact of Cyber Incidents', displayName: 'Impact of Cyber Incidents'},
  };

  return (
    <div className="app-container">
      
      <div className="button-container">
       
            <button className='graphButton'> <Link style={{textDecoration: 'none', color:'white' }}to={'/Dashboard'}>
            <svg width="30px" height="30px" viewBox="0 0 24 24" style={{ marginRight: '10px' }}>
              <path d="M4 4V20M8 12H20M8 12L12 8M8 12L12 16" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span>Main Dashboard</span>
              
            </Link></button>
        
        {Object.keys(graphImages).map((buttonName) => (
          <button
            key={buttonName}
            className={`graphButton ${selectedButton === buttonName ? 'selected' : ''}`}
            onClick={() => handleButtonClick(buttonName)}
          >
            {graphImages[buttonName].displayName}
          </button>
        ))}
      </div>
      <div className='graph-container'>
            <p className='graphTitle'>{graphImages[selectedButton].title}</p>
            <img src={graphImages[selectedButton].src} alt={`Graph for ${selectedButton}`} />
      </div>
    </div>
  );
};

export default DashGraphs;
