import React, { useState, useEffect } from 'react';
import PasswordChangeForm from './PasswordChange';
import { Typography } from '@mui/material';
import { auth } from '../Firebase_Config/firebase';

const AccountManage = () => {
    const [userEmail, setUserEmail] = useState(null); // State to store user's email

    useEffect(() => {
        // Check if there's a user signed in
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                // User is signed in, set userEmail state
                setUserEmail(user.email);
            } else {
                // No user is signed in
                setUserEmail(null);
            }
        });

        // Clean up subscription to avoid memory leaks
        return () => unsubscribe();
    }, []);

    const pageStyle = {
        textAlign: "justify",
        WebkitTransition: 'all',
        msTransition: 'all',
        minHeight: '77vh'
    };

    return (
        <div className='ContainerBoxAcc'>
            <div style={pageStyle}>
                <h2 className="underline">
                {'Account Management'}
                </h2>
                <br></br>
                <h4 className='fontChange'>
                {'Want to Change your Password?'}
                </h4>
                <p className='fontChange'>
                {'Enter your new password and then repeat.'}
                {' Then click the reset button'}.
                </p>
                {userEmail && (
                <p className='fontChange'>
                    {'Logged in as: ' + userEmail}
                </p>
                )}
                <PasswordChangeForm />
                <p className='fontChange'>
                {'Any problems that you cannot resolve please contact'}
                </p>
            </div>
        </div>

    );
};

export default AccountManage;
